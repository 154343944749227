
@wrapperPadding : 30;



@gridRowWidth1200Wrapper:        (@container-lg) + @wrapperPadding;
@media (min-width: 1200px) {
	.container.wrapper {
		width: @gridRowWidth1200Wrapper;
	}

}

.wheather-cal-webcam{
	background-position: top center;
	background-repeat: no-repeat;
	height: 288px;
}

.forecast{
	margin-top: 8px;
}
.fc-ws{
	font-size: 2.2em;
	font-weight: bold;
}
.fc-cs{
	font-size: 1.4em;
	margin-left: 1px;
}

.fc-more{
	text-align: right;
	font-size: 13px;
}

.forecast-webcam .today{
	font-size: 13px;
	color: @text-muted;
}

#slider-1211 .owl-height{
	min-height: 400px;
}




.google-search iframe {
	height:1860px; width:100%;
}


//menu
.navbar-nav > li{
	display: flex;
}


.caret-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: none;
	border-radius: 0px;
	border-width: 0;
	box-sizing: border-box;
	display: block;
	font: normal normal 400 1rem/1.25rem arial,sans-serif;
	font-weight: 400;
	font-weight: 400;
	margin: 0;
	padding: .75rem .25rem;
	text-decoration: none;
	margin-right: .25rem;
	color:white;
}

.caret-button:focus {
	color: @navbar-default-brand-hover-color;
	background-color: @navbar-default-brand-hover-bg;

}



li.open .ecl-button {
	background-color: @navbar-inverse-link-hover-bg;
}




ul.timeline > li::before {
	content: ' ';
	background: green;
	display: inline-block;
	position: absolute;
	border-radius: 50%;
	border: 3px solid green;
	left: 20px;
	width: 20px;
	height: 20px;
	z-index: 400;
}


ul.timeline > li.closed::before {

	background: dimgrey;
	border: 3px solid dimgrey;


}
