@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&subset=latin-ext');

html {
    font-size: 95%;
}

body {
    font-size: 95%;
    color: #000;
    background-color: #f2f2f2;
}


.header-outer {
    background-color: #09428f;
}

.header-outer, .header-outer a {
    color: #FFF;
}

#header-inner {
    padding-bottom: 30px;
    padding-top: 15px;
}

#tool-links, #tool-links a {
    color: #545454;
}

#tool-links {
    padding: 0px 0px 8px;
}

.navbar {
    margin-bottom: 0px;
}

.navbar-inner {
    padding: 0px;
}

#search-form {
    margin-bottom: 0px;
}

.wimg {
    height: 21px;
}

.ext hr {
    margin: 8px 0;
}

#navigation .btn-navbar {
    font-size: 18px;
    /*font-family: Arial, Helvetica, sans-serif;*/
    margin-bottom: 3px;
}

.navbar a, .side-nav a, .btn {
    text-decoration: none;

}

.yamm .title {
    font-weight: 500;
    font-size: 15px;
}

.owl-dots-right .owl-controls .owl-dot {
    border: 0 none;
}

.owl-dots-top-right  .owl-dots {
    position: absolute;
    right: 10px;
    top: 10px;
}

.box1 {
    background: rgba(0, 0, 0, 0) url("../images/header-long.jpg") no-repeat scroll left top;
    padding: 5px 10px 10px 10px;
    background-color: #E8E8E5;

}

.link-more-records .btn {
    line-height: 1.5;
    border-radius: 0;
    margin-top: 10px;
    border: none;

    color: #706809;
    background-color: transparent;
    /*text-transform: uppercase;*/
    font-weight: 500;
    text-decoration: underline;
}

.link-more-records a::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 17px;
    height: 17px;
    margin-right: 5px;
    background: transparent url('../images/001-right-arrow.svg') no-repeat top left;
}

.link-more-records.link-more-records-external a::before {
    background: none;
}




.media-heading {
    /*font-size: 15px;*/
    /*font-weight: 500;*/
}

.wheather-cal {
    margin-bottom: 30px;

}

.wheather-cal a {
    text-decoration: none;
}

.input-group-btn {
    font-size: 0.92em;
}

.footer-outer a {
    color: white;
    text-decoration: underline;
}

.footer-outer h2 {
    color: white;
}

.footer-outer {
    background: #3d3d3d;
    color: white;
    margin-bottom: 20px;
}

#footer-inner {
    /*padding-top: 20px;*/
    /*padding-bottom: 20px;*/
}

.copy-outer {
    color: #424952;
    text-align: center;
}

.col-hours, .col-find-us {
    padding: 20px 20px;

}

.col-contact,.col-partners {
    /*background-color: green;*/
    padding: 20px 20px;

}

.copy-outer a {
    color: #424952;
    text-decoration: underline;

}

.footer .widget-footer .social-icon > li > a {
    background-color: #212121;
    border-bottom: medium none;
    border-radius: 3px;
    display: block;
    height: 40px;
    position: relative;
    text-align: center;
    width: 40px;
}

.social {
    background-color: #3B579D;
    border-bottom: medium none;
    border-radius: 5px;
    display: block;
    height: 40px;
    position: relative;
    text-align: center;
    width: 40px;
    text-decoration: none;

}

.social span {
    margin-left: 50px;
    font-size: 18px;
    line-height: 40px;
}

.social i {
    text-decoration: none;
    top: 5px;
    font-size: 22px;
    height: 40px;
    left: 0;
    line-height: 32px;
    position: absolute;
    text-align: center;
    width: 40px;
}

.visitors {
    margin: 20px 0px;;

}

.column-main-inner {
    background-color: #FFF;
}

.column-right .column-bordered {
    background-color: #25336b;
    padding: 20px;
}

.column-inverse, .column-inverse a {
    background-color: #3d3d3d;
    color: white;
    /*324591*/
}

.column-inverse h2 {
    background-color: #3d3d3d;
    padding: 20px 0px 10px 0px;
    font-size: 18px;
    color: #FFF;
    margin: 0;
    border-bottom: none;
}

/*.nav-2-col start*/

.nav-2-col .box {
    background-color: #324591;
    color: #fff;
    margin-top: 20px;
    padding: 10px 5px;
    display: block;
    text-align: center;
}

.nav-2-col .box:hover {
    background-color: #fff;
    color: #333;

    text-decoration: none;
}

.nav-2-col .box div {
    display: table;
    height: 100%;
    width: 100%;
}

.nav-2-col .box div span {
    display: table-cell;
    vertical-align: middle;
}

.nav-2-col .row-first .box {
    margin-top: 0px;
}

.topbar-simple {
    font-size: 11px;
}

/***/
.topbar {
    padding: 15px 0px;
}

.side-nav .m-box h2 {
    padding-left: 15px;
    text-transform: uppercase;
    border-bottom: 1px solid #2a2a2a;
    padding-top: 25px;
    padding-bottom: 15px;
}

/*.side-nav .m-holder {*/
/*padding: 10px 20px;*/
/*background-color: #3d3d3d;*/

/*}*/

.input-search {
    background-color: #e9e9e9;
    border-color: #e9e9e9;
}

#search-form .btn {
    background-color: #ffee01;
    padding: 6px 18px;
    margin-left: 1px;
}

.top-1 {
    font-size: 13px;
}

.top-1, .top-1 a {
    color: #525252;

}

.topbar i {
    padding-right: 0px;
}

.block-inverse-box {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 30px;
}

.block-inverse-box a {
    text-decoration: none;
}

.block-inverse-box .b-item {
    background-color: #e9e9e9;
    margin-top: 20px;
    padding: 10px 5px;
    display: block;
    text-align: center;
}

.block-inverse-box .b-inner {
    display: table;
    height: 100%;
    width: 100%;
}

.block-inverse-box .b-inner span {
    vertical-align: middle;
    display: table-cell;
    font-size: 16px;
    text-transform: uppercase;
}

.block-inverse-box .first .b-item {
    margin-top: 0px;

}

.block-inverse-box .b-item:hover {
    background-color: #CCC;
}

.block-inverse-box .b-inner:hover {
    color: #333;
    text-decoration: none;

}

.link-more-records {
    text-align: right
}

.block-slider {
    position: relative;
    /*margin-bottom: 0px;*/
}

/*.owl-dots-top-right .owl-controls .owl-dots{*/
/*right: 30px;*/
/*top: 30px;*/

/*}*/

.block-default {
    /*border: 1px solid #c6c6c6;*/
    /*padding: 20px  40px;*/
}

.block-default h2 {
    /*font-weight: bolder;*/
    /*text-transform: uppercase;*/
    /*color: #108626;*/
    /*font-size: 20px;*/
}

.section-white {
    background-color: white;
}

.section-block {
    /*padding-top: 40px;*/
}

.text-col strong {
    font-size: 16px;
}

.owl-outer {
    position: relative;
}

.owl-outer .date {
    font-size: 12px;
}

.owl2-carousel-v1-prev {
    left: 0;
}

.owl2-carousel-v1-arrow {
    position: absolute;
    top: 50%;
    z-index: 1;
    width: 40px;
    height: 40px;
    margin-top: -25px;
    display: inline-block;
    font-size: 20px;
    background: #fff;
    color: #333;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    padding: 3px;
    transition: .3s;
    transform: translate3d(0, -50%, 0);
}

.owl2-carousel-v1-next {
    right: 0;
}

.owl2-carousel-v1-prev::before {
    font-family: FontAwesome;
    content: "\f104";
}

.owl2-carousel-v1-next::before {
    font-family: FontAwesome;
    content: "\f105";
}

.date-outer {
    text-align: center;
    float: left;
    width: 80px;
    color: #333;
    padding: 6px;
    background-color: white;
    margin-right: 15px;
}

.date-outer .month {
    background-color: #0c661d;
    color: white;
    display: block;

}

.date-outer .day-no {
    font-size: 28px;
    display: inline;
}

/*.block-inverse, .block-inverse h2, .block-inverse a*/

.owl-events .owl-carousel-inverse .item,
.owl-events .block-inverse,
.owl-events .owl-carousel-inverse a,
.owl-events .owl-carousel-inverse h2 {
    background-color: #333;
}

.block-semi .magazine-mini-news {
    background-color: #f6f6f6;
    padding: 10px;
    margin-bottom: 10px
}

.comment {
    margin-bottom: 10px;
}

.comment img {
    float: left;
    width: 50px;
    height: 50px;
    margin-right: 15px;
}

.comment-body {
    overflow: hidden;
}

.section-default .container-inner {
    background-color: white;
    border-top: 1px solid #f2f2f2;
}


.motive-outer {
    background: url("../images/top.jpg") top center no-repeat;
    height: 300px;
}

#motive {
    border: white solid 20px;

}

.twocol-3-9 .section-main {
    margin-top: 20px;
    margin-bottom: 35px;
}




.section-black {
    background-color: #d8d7d7;
}

.section-black .container-inner {
    background-color: #313031;

}

.section-black .container-inner, .section-black .container-inner h2, .section-black .container-inner h3, .section-black .container-inner a {
    color: white;
}

.block-center {
    text-align: center;
}

.home h3{
    font-size: 14px;
    font-weight: 700;
}

.home .date{
    font-size: 13px;
}



.top-1 ul{
    line-height: 20px;
    margin-bottom: 10px;
}

.top-1{
    text-transform: lowercase;
}

.top-1 .lang {
    text-transform: uppercase;
    font-weight: bold;
}


.top-1 li.first.lang{
    padding-left:10px;

}

.top-1 .lang.current{
    text-decoration: underline;

}


.jumbotron-text .btn{


    border: none;
    border-radius: 5px;
    padding: 15px 20px;
    position: relative;
    z-index: 1;
    background-color: #ffeb3b;
    color: black;
}

.jumbotron-text .btn::after {
    background-color: #fdd835;
    border-radius: 5px;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: width 0.35s ease;
    transition: width 0.35s ease;
    width: 0;
    z-index: -1;
}

.jumbotron-text .btn:hover::after
{
    width: 100%
}


.navbar .nav > li > a {
    text-transform: uppercase;
    font-size: 14px;
    padding-left: 7px;
    padding-right: 7px;
    font-weight: bold;
}



.btn-grey .btn-section{
    background-color: #cfcfcf;
}

.btn-grey .btn-section::after{
    background-color: #9e9e9e;
    color: white;
}


.btn-green .btn-section{
    background-color: #99d066;
}

.btn-green .btn-section::after{
    background-color: #689f38;

}

.btn-green .btn-section{
    background-color: #99d066;
}

.btn-green .btn-section::after{
    background-color: #689f38;

}



.btn-section{
    border: none;
    border-radius: 5px;
    padding: 15px 20px;
    position: relative;
    z-index: 1;
    background-color: #ffeb3b;
    color: black;
    width:100%;
    margin-bottom: 20px;
    white-space:normal;
    vertical-align: middle;
}


.home .btn-section{
    min-height: 73px;
}



.btn-section::after {
    background-color: #fdd835;
    border-radius: 5px;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: width 0.35s ease;
    transition: width 0.35s ease;
    width: 0;
    z-index: -1;
}

.btn-section:hover::after
{
    width: 100%
}


.b-inner .b-title{
    font-weight: 600;
}

.faq-q{
    font-weight: bolder;
}


.ocenenia{
    position: relative;
}

.zlaty-erb {
    position: absolute;
    left: -10px;
    top: 10px;
}

.tis {
    position: absolute;
    left: 210px;
    top: 10px;
}


.tgr-hero__btn{
    font-weight: bold;
    background-color: #ffee01;
    border: none;
}

.tgr-hero__btn:hover{
    font-weight: bold;
    background-color: #ffff00;
    border: none;
}


.tgr-hero, .tgr-hero a, .tgr-hero h2, .tgr-hero a:hover {
    color: black;

}

.home-title {
    font-size: 1.4166666666666667em;
    margin-left: 30px;
    margin-top: 15px;
    margin-bottom: 0px;
    color: #0c661d;
}