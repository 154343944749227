/*!
 * Egov
 */

@baseExtra: "../../../css/less.new/";
@base: "../../../css/less.new/bootstrap-master-3/less.w3c";
@base0: "../../../css/less.new/bootstrap-master-3/less";
/*for w*/
@baseExt: ".w3c";

// Core variables and mixins
@import "./variables-my.less"; // Modify this for custom colors, font-sizes, etc
@import "@{base}/mixins.less";

// Reset and dependencies
@import "@{base}/normalize.less";
@import "@{base}/print.less";
@import "@{base}/glyphicons.less";

// Core CSS
@import "@{base}/scaffolding.less";
@import "@{base}/type.less";
@import "@{base}/code.less";
@import "@{base}/grid.less";
@import "@{base}/tables.less";
@import "@{base}/forms.less";
@import "@{base}/buttons.less";

// Components
@import "@{base}/component-animations.less";
@import "@{base}/dropdowns.less";
@import "@{base}/button-groups.less";
@import "@{base}/input-groups.less";
@import "@{base}/navs.less";
@import "@{base}/navbar.less";
@import "@{base}/breadcrumbs.less";
@import "@{base}/pagination.less";
@import "@{base}/pager.less";
@import "@{base}/labels.less";
@import "@{base}/badges.less";
@import "@{base}/jumbotron.less";
@import "@{base}/thumbnails.less";
@import "@{base}/alerts.less";
@import "@{base}/progress-bars.less";
@import "@{base}/media.less";
@import "@{base}/list-group.less";
@import "@{base}/panels.less";
@import "@{base}/responsive-embed.less";
@import "@{base}/wells.less";
@import "@{base}/close.less";

// Components w/ JavaScript
@import "@{base}/modals.less";
@import "@{base}/tooltip.less";
@import "@{base}/popovers.less";
@import "@{base}/carousel.less";


@import "@{baseExtra}/menu-simple/menu-simple.less";

// Utility classes

@import "@{baseExtra}/weather/weather-icons.less";
@import "@{base}/utilities.less";
@import "@{base}/responsive-utilities.less";
@import "@{baseExtra}/magnific-popup/magnific-popup.less"; 




/*

*/


@import "@{base0}/tangram3.less";
@import "@{base0}/responsive-margins.less";

/*
@import "@{baseExtra}/amsul-picker/default.less"; 
@import "@{baseExtra}/amsul-picker/default.date.less"; 
@import "@{baseExtra}/amsul-picker/tangram-picker.less"; 
*/

@import "@{baseExtra}/font-awesome/less.w3c/font-awesome.less";



@import "page.less";
@import "./boxes.less";

@import "./sidenav-v1.less";
@import "./breadcrumb-v1.less";

@import "./nav-list.less";
@import "./topbar.less";

@import "./yamm.less"; //megamenu
@import "./metisMenu.less"; //sidemenu
@import "./navbar.justify.less"; //sidemenu

@import  "./menu.multi.color.less";


@import "@{baseExtra}/owl.carousel@{baseExt}/owl.carousel.less"; 
@import "@{baseExtra}/owl.carousel@{baseExt}/owl.theme.less"; 
//@import "./owl-slider-002.less";
@import "@{baseExtra}/owl.carousel/tangram.less"; 
@import "./owl-slider-003.less";

@import "@{baseExtra}/slick/slick.less";
@import "@{baseExtra}/slick/slick-theme.less";



/*
*/

//@import "./jquery.progresstracker.less";

@import "./sidenav.less";
@import "./sidenav.collapse.less";
@import "./breadcrumb-v1.less";
@import "./nav-section.less";
@import "./headings.less";
@import "./blocks.less";
@import "./sections.less";
@import "./bootstrap-datepicker3.less";
@import "./navbar-menu-wcag-fix.less";

//@import "@{baseExtra}/futbal-net/futbal-net.less";


@import "./booking.less";

@import (less) "./master.css";


@media (min-width: @grid-float-breakpoint-max) {

	@import "./navbar-desktop.less";
	@import "./page-desktop.less";

}

@media (max-width: @grid-float-breakpoint) {

	@import "./navbar-phone.less";
	@import "./page-phone.less";

}

//@import "./carousel-acsbl.less";
