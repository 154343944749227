//menu
.navbar-nav > li{
  display: flex;
}


.navbar .nav > li > a.dropdown-toggle {
  padding-right: 0px;
}

.caret-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border-radius: 0px;
  border-width: 0;
  box-sizing: border-box;
  display: block;
  //font: normal normal 400 1rem/1.25rem arial,sans-serif;
  //font-weight: 400;
  //font-weight: 400;
  margin: 0;
  padding: .75rem .50rem;
  text-decoration: none;
  margin-right: .25rem;
  color:white;
}

li.active .caret-button, .caret-button:focus {
  color: @navbar-inverse-link-hover-color;
  background-color: @navbar-inverse-link-hover-bg;
}

li.active .caret-button:focus {
  background-color: black;
}



li.open .caret-button {
  background-color: @navbar-inverse-link-hover-bg;
}

