

.section-white-yellow .container-inner .column-main {
  background-color: #FFF;
  position: relative;
}


.section-white-green .container-inner .column-main {
  background-color: #FFF;
  position: relative;
}



@media (min-width: @grid-float-breakpoint-max) {

  .section-white-yellow .container-inner .column-main::after {
    content: "";
    position: absolute;
    left: 0px;
    height: 100%;
    width: 15px;
    top:0px;
    background-color: #e8e7e7;
  }

  .section-white-yellow {
    background-color: #e8e7e7;
  }

  .section-default.section-white-yellow .container-inner {
    background-color: #fae46a;
  }

  //

  .section-white-green .container-inner .column-main::after {
    content: "";
    position: absolute;
    left: 0px;
    height: 100%;
    width: 15px;
    top:0px;
    background-color: #e8e7e7;
  }

  .section-white-green {
    background-color: #e8e7e7;
  }

  .section-default.section-white-green .container-inner {
    background-color: #2e7d32;
  }

}