
.define-if-not-defined() {

	@wheather-cal-bg: #FFCB00;
	@wheather-cal-color: #FFF;

}

.define-if-not-defined();



.wheather-cal, .wheather-cal a{
	color: #333333;
}

.wheather-cal a:hover{
	text-decoration: none;
	
}

.wi-s{
	font-size: 55px;
	padding-top: 30px;
}

.wc-cdt{
	font-size: 35px;
	color: @wheather-cal-color;
}

.wi-s a,.wi-s a:hover{
	color: @wheather-cal-color;
}

.wc-cdn{
	font-weight: bold;
	text-transform: capitalize;
}

.wc-cdh {
	font-weight: bold;
}

.wi-name, .wc-i span{
	display: block;
}
