.button-more {
  align-items: center;
  border: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  min-width: 4rem;
  padding: 0.6rem 0.8rem;
  text-decoration: none;
  position: relative;
  transition: background-color .3s ease;
  background-color: #fff;
}

.button-more:before {
  background-color: @my-brand-primary;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  transition: width .3s ease;
  width: 0;
}

.button-more-text {
  color: @my-brand-primary;
  display: block;
  //pointer-events: none;
  position: relative;
  text-align: center;
  transition: color .3s ease;
  z-index: 1;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bolder;
}

.button-more:hover {
  text-decoration: none;
}

.button-more:focus:before, .button-more:hover:before {
  width: 100%;
}
.button-more:focus .button-more-text, .button-more:hover .button-more-text {
  color: #fff;
  text-decoration: none;
}
