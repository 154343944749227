.extra{
  color: #e54d4e;
  font-weight: bold;
}

.c-left{
  clear: left;
}

.t-left{
  text-align: left;
}

.d-b{
  display: block;
}


.box-round-tr{
  position: absolute;
  top: 5px;
  right: 5px;
  height: 36px;
  width: 36px;
  margin-top: 5px;
  text-align: center;
  line-height: 36px;
  font-size: 12px;
  font-weight: 600;
  color: #000;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #ffdb31;
  z-index: 2;
  font-family: "Open Sans", Arial, sans-serif;
}