.table-calendar{
  font-size: 1rem;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.8em;
  margin-top: -0.8em;
}





.table-calendar > thead > tr > th{
  border: none;
  width: 14.28%;
}

.table-calendar > thead > tr.cal-days > th{
  text-align: center;
}


.table-calendar > tbody > tr > td {
  width: 14.28%;
  border: none;
  padding: 0px;
  position: relative;
  height: 2.5em;
  font-size: 1em;
  text-align: center;
  z-index: 0;
  box-sizing: border-box;
  margin-bottom: 5px;
}



.table-calendar tbody td a{
  text-decoration: none;
  position: absolute;
  line-height: 2.5em;
  width: 2.5em;
  margin-left: -1.25em;
  top:0 ;
  left:50%;
  font-size: 1rem;

}


.table-calendar tbody td::before {
  width: 2.5em;
  height: 2.5em;
  margin: -1.25em 0 0 -1.25em;
  content: "";
  display: block;
  border-radius: 500px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: -1;
  border: 2px solid #e0e0e0;
}


.table-calendar tbody td.alive::before {
  background-color: #eeeeee;
}

.table-calendar tbody td.actual::before {
  background-color: #fff;
}