
.breadcrumb.breadcrumb-default{
	background-color: transparent;
	padding-left: 0px;
	padding-right: 0px;
	margin-bottom: 25px;
}


.breadcrumb-default-full{
	padding: 10px 0px;
	background-color: @my-breadcrumb-bg;
}


.breadcrumb-default-full-v2{
	padding: 12px 0px;
	text-transform: uppercase;
	background-color: @my-breadcrumb-bg;
	font-size: @font-size-small;
}

.breadcrumb-default-full-v3{
	border-bottom: 1px solid  darken(@my-breadcrumb-bg, 6%);
	font-size: @font-size-small;

}

.breadcrumb-default-full-v4{
	margin-top: 20px;
	padding: 5px 0px;

}

.breadcrumb-default-full .breadcrumb{
	margin-bottom: 0px;
	background-color: transparent;
}

.breadcrumb {
	margin-bottom:10px;
	font-size: @my-breadcrumb-font-size;
}

.breadcrumb ol{
	margin: 0px;
	padding-left: 10px;

}

.breadcrumb, .breadcrumb a{
	color: lighten(@my-breadcrumb-color, 30%);

}


.breadcrumb > li:last-child a {
	color: @my-breadcrumb-color;

}

.breadcrumb > li + li::before {
	font-family: FontAwesome;
	content: "\f105";
	color: lighten(@my-breadcrumb-color, 30%);

}

.breadcrumb li.first::before {
	font-family: FontAwesome;
	content: "\f015";
	margin-right: 3px;
}