
.block-green, .block-green a, .block-green h2,.block-green.footbal-info .bl-cap{
  color: white;
  background-color: #2e7d32;

}

.block-yellow .link-more-records .btn{
  color: #0f1d07;

}


.block-yellow, .block-yellow a, .block-yellow h2,.block-yellow.footbal-info .bl-cap{
  color: #0f1d07;
  background-color: #fae46a;

}



.block-grey{
  background-color: #F7F7F7;
  position: relative;
}


@media (min-width: @grid-float-breakpoint-max) {

  .column-br{
    border-right: 1px solid #f2f2f2;
  }

  .column-bl{
    border-left: 1px solid #f2f2f2;
  }


  .column-right .block-default {
    padding: 25px 25px 25px 10px;
  }

  .column-main .block-default{
    padding: 25px 15px 25px 30px;

  }





  .block-grey-after::after{
    content: "";
    position: absolute;
    left: -15px;
    height: 100%;
    width: 15px;
    top:0px;
    background-color: #F7F7F7;
  }


}
@media (max-width: @grid-float-breakpoint) {



  .block-default{
    padding: 15px 10px 30px 10px;
  }

}


.spravodaj {
  position: relative;
  height: 160px;
}

.spravodaj h2{
  padding-left: 10px;
}


.spravodaj, .spravodaj h2{
  color: white;

}

.spravodaj .media-today-outer{
  overflow: hidden;
  height: 160px;
  padding-top: 20px;
  background-color: #008000;

}

.spravodaj::after{
  content: "";
  position: absolute;
  left: -15px;
  height: 160px;
  width: 15px;
  top:0px;
  background-color: #008000;
}

.spravodaj img{
  margin-left: 40px;
  transform:          rotate(-5deg);
  -ms-transform:      rotate(-5deg);
  -moz-transform:     rotate(-5deg);
  -webkit-transform:  rotate(-5deg);
  -o-transform:       rotate(-5deg);
}
