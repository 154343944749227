
//https://demo.essentialplugin.com/slick-slider-demo/https://demo.essentialplugin.com/slick-slider-demo/

@charset "UTF-8";

// Default Variables



@slick-font-path: "./fonts/";
@slick-font-family: "slick";
@slick-loader-path: "./";
@slick-arrow-color: white;
@slick-dot-color: black;
@slick-dot-color-active: orange;
@slick-prev-character: "←";
@slick-next-character: "→";
@slick-dot-character: "•";
@slick-dot-size: 6px;
@slick-opacity-default: 1;
@slick-opacity-on-hover: 1;
@slick-opacity-not-active: 1;
@slick-opacity-active: 1;

.slick-sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  //-webkit-clip-path: inset(50%) !important;
  //clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}


.slick-button{
  margin-right: 5px;
  color: black;
  background-color: transparent;
}

.slick-dots {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: inline-block;

  li {
    position: relative;
    display: inline-block;
    margin: 0px;
    padding: 0;

    button {
      // Hover and focus states
      &:hover,
      &:focus {
        .slick-dot-icon {
          opacity: @slick-opacity-active;
        }
      }

      &:focus {
        .slick-dot-icon:before {
          color: orange;
        }
      }

      // Inner dot icons
      .slick-dot-icon {
        color: @slick-dot-color;
        opacity: @slick-opacity-not-active;
      }
    }


    // Active dot
    &.slick-active {
      button {
        &:focus {
          .slick-dot-icon {
            color: orange;
            opacity: 1;
          }
        }
        .slick-dot-icon {
          color: @slick-dot-color-active;
          opacity: @slick-opacity-active;
        }
      }
    }
  }
}


.slick-slider.slick-margin-bottom .slick-list {
  margin-bottom: 10px;
}

.slick-button.slick-next,
.slick-button.slick-prev {
  color: white;
  background-color: black;
  position:absolute;
  top:50%;
  display:block;
  padding:0;
  height:55px;
  width:30px;
  line-height:0;
  font-size:24px;
  cursor:pointer;
  border:none;
  transform:translate(0,-50%);
  opacity:0.9;
}

.slick-extra-space{
  .slick-button.slick-next,
  .slick-button.slick-prev {
    margin-top: -15px;
  }


}


.slick-next{
  right:0px;
  margin: 0px;
}

.slick-prev{
  left:0px;
  margin: 0px;

}


.slick-next:focus,
.slick-next:active ,
.slick-next:hover,
.slick-prev:focus ,
.slick-prev:active,
.slick-prev:hover {
  color: white;
  outline: none;
}

.slick-next:focus-visible,.slick-prev:focus-visible, .slick-autoplay-toggle-button:focus-visible {
  background-color: orange;
  color: white;
}


.slick-next:hover,
.slick-prev:hover {
  opacity: 0.7;
}


.navigation-outer{
  text-align: center;
}

.slick-autoplay-toggle-button{
  padding:0;
  height:27px;
  width:24px;
  line-height:0;
  font-size:15px;
  cursor:pointer;
  border:none;
  opacity:0.9
}

.slick-autoplay-toggle-button:hover, .slick-autoplay-toggle-button:focus, .slick-autoplay-toggle-button:active{
  color: black;
  opacity: 0.8;
}

.owl-slider-002 .item{
  background-color: #fff;
  background-position: center 55%;
  width: 100%;
  background-size: cover;

}


.slick-autoplay-toggle-button:focus,
.slick-autoplay-toggle-button:active ,
.slick-autoplay-toggle-button:hover{
  outline: none;
}


.slick-slider-bottom-margin{
  margin-bottom: 30px;
}

//slider + navigacia ako obrazky
.slider-nav {

  margin-top: 10px;

  .slick-slide {
    cursor: pointer;
    //outline: none;
  }
  .slick-slide.slick-current.slick-active {
    opacity: 1;
  }

  .slick-slide img {
    padding: 5px;
    background: transparent;;
  }

  .slick-slide.slick-current.slick-active img {
    background: #000;
  }

  img {
    //width: 40px;
    margin: 0 5px;
  }

  .slick-slide:first-child img {
    margin-left: 0;
  }
  .slick-slide:last-child img {
    margin-right: 0;
  }

}
