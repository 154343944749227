h1{
  margin-bottom: 25px;
  margin-top: 0px;
  color: @my-headings-color;
  font-size: floor((@font-size-base * 2));
  font-weight: @my-headings-font-weight;
  position: relative;
  padding-bottom: 0px;
  font-family: @my-headings-font-family;

}


h2{

  font-size: floor((@font-size-base * 1.3));
  font-family: @my-headings-font-family;
  text-transform: uppercase;
  font-weight: normal;
  color: #0c661d;
}

h3{
  font-size: floor((@font-size-base * 1.1));
  font-family: @my-headings-font-family;

}



