
.metismenu .arrow {
  float: right;
  line-height: 1.42857;
}

.metismenu .fa.arrow:before {
  content: "\f067";
}
.metismenu .active > a > .fa.arrow:before {
  content: "\f068";
}


.metismenu .collapse {
  display: none;
}
.metismenu .collapse.in {
  display: block;
}
.metismenu .collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
       -o-transition-timing-function: ease;
          transition-timing-function: ease;
  -webkit-transition-duration: .35s;
       -o-transition-duration: .35s;
          transition-duration: .35s;
  -webkit-transition-property: height, visibility;
  -o-transition-property: height, visibility;
  transition-property: height, visibility;
}
