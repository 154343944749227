.navbar-inverse{
	margin-top: -75px;
}

.jumbotron-outer{
	height: 400px;
	width: 100%;
}

.jumbotron-text{
	text-align: center;
	padding-top: 120px;
	color: white;
}

.home .navbar{
	margin-top: -75px;
}

.content-inner {
	margin-top: 20px;
	/*margin-bottom: 40px;*/
	background-color: white;
}






.top-1 li{
	padding: 0 6px;
	border-left: 1px #dddddd solid;
}

.top-1 li.first, .top-1 li.lang{
	border:none;
}


//
.navbar .nav > li.nav-home > a{
	background-color: #ffee01;
	color: black;
	padding-left:16px ;
	padding-right:16px ;

}

.nav-home a{
	color: black;
}


#myNavbar1{
	padding-left:0px ;
	padding-right:0px ;

}

.navbar{
	border: none;
}

.home h2{
	margin-top: 0px;
	padding-top: 0px;
	//border-left: 6px solid #F8E273;
	//padding-left: 10px;
	font-weight: 700;
}

.column-main-inner {
	padding: 50px 30px 100px;
}


.section-3 .section-title{
	padding-left: 0px;
}


.jumbotron-text .desc{
	font-size:28px ;
	font-weight: bold;
	margin-bottom: 10px;
	background-color: rgba(34, 34, 34,.4);
	padding: 5px;
	box-shadow: 0 0 10px 5px rgba(34, 34, 34, 0.4);
}


//.img-col{
//	height: 200px;
//}

.img-col {
	height: 360px;
	overflow: hidden;
	padding-top: 30px;
	background-color: #2E7D32;
	color: white;
}
.img-col img{
	margin-left: 20px;
	//transform:          rotate(-1deg);
	//-ms-transform:      rotate(-1deg);
	//-moz-transform:     rotate(-1deg);
	//-webkit-transform:  rotate(-1deg);
	//-o-transform:       rotate(-1deg);
}

.events__featured-link {
	position: absolute;
	right: -20px;
}

.link-read-more{
	text-transform: lowercase;
	font-size: 13px;
	display: inline-block;
	display: none;
}

.section-olost{
	border-top: 1px solid #f2f2f2;
	.row{
		padding-left: 20px;
	}

	.olo-grid{
		padding-top: 30px;
		padding-bottom: 30px;

	}
}

.section-title {
	padding-left: 35px;
}

.tgr-hero h2 {
	color: white;
	font-size: 32px;
	text-shadow: -5px 0 black, 0 5px black, 5px 0 black, 0 -5px black;
	margin-top: -40px;
}

.booking-month-day-calendar ul li {
	list-style-type: none;
	padding: 1.5rem 2rem;
	background: #fff;
	color: #7e7e7e;
	cursor: pointer;
	width: 48%;
	display: block;
	float: left;
	margin: 0 2% 2% 0;

}
