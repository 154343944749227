
.topbar{
	z-index: 0;
	min-height: @topbar-height;
	line-height: @topbar-height;
}

.topbar ul{
	margin: 0px;
}


.topbar-inverse {
	background: @topbar-inverse-bg;
}

.topbar-inverse a, .topbar-inverse{
	color: @topbar-inverse-link;

}

.topbar-default {
	background-color: @topbar-default-bg;
	border-bottom: 1px solid darken( @topbar-default-bg, 10%);
}



.topbar-default a, .topbar-default{
	color: @topbar-default-color;

}

.topbar i {
	padding-right: 5px;
}