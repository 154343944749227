.attributes-table p {
  margin-bottom: 6px;
}
.attributes-table span.attr-val {
  margin-left: 5px;
}
.attributes-table .caption {
  display: inline-block;
  font-weight: bolder;
  min-width: inherit;
  padding: 6px 0px ;
  min-width: 25%;
  text-transform: uppercase;
  vertical-align: top;
}
.attributes-table span {
  display: inline-block;
  font-weight: normal;
  margin-left: 0;
  min-width: 74%;
  padding: 6px 0px;
}
