.owl-theme .owl-controls {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent
}

.owl-theme .owl-controls .owl-nav [class*=owl-] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #d6d6d6;
    display: inline-block;
    cursor: pointer;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.owl-theme .owl-controls .owl-nav [class*=owl-]:hover {
    background: #869791;
    color: #fff;
    text-decoration: none
}

.owl-theme .owl-controls .owl-nav .disabled {
    opacity: .5;
    cursor: default
}

.owl-dots-default .owl-dots .owl-dot {
    display: inline-block;
    //fix w3c
    //zoom: 1;
}

.owl-dots-default .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #FFF;
    display: block;
    -webkit-backface-visibility: visible;
    -webkit-transition: opacity 200ms ease;
    -moz-transition: opacity 200ms ease;
    -ms-transition: opacity 200ms ease;
    -o-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px
}

.owl-dots-default .owl-dots .owl-dot.active span, .owl-dots-default .owl-dots .owl-dot:hover span {
    background: yellow;
}


/**/

.owl-dots-light .owl-dots .owl-dot span {

	background-color: #000;
	opacity: 0.2;
	width: 25px;
	height: 3px;
	margin: 5px;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.owl-dots-light .owl-dots .owl-dot.active span, .owl-dots-light .owl-dots .owl-dot:hover span {
	opacity: 1;

}



/**/

.owl-dots-right .owl-controls .owl-dots {
    text-align: center;
}
.owl-dots-right .owl-controls .owl-dot {
    background-color: #f0f0f0;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    display: inline-block;
    height: 16px;
    margin: 10px 5px 5px;
    width: 16px;
}
.owl-dots-right .owl-controls .owl-dot.active {
    background-color: @my-brand-primary;
}

.owl-dots-right .owl-controls .owl-dots {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    bottom: 20px;
    padding: 0 5px;
    position: absolute;
    right: 20px;
}
.owl-dots-right .owl-controls .owl-dot {
    border: 0 none;
}

/***/

.owl-theme.owl-arrows .owl-controls .owl-nav div {
	opacity: 0;
	position: absolute;
	top: 50%;
	background: @my-brand-primary;
	color: #fff;
	padding: 0;
	height: 70px;
	width: 40px;
	border-radius: 0;
	line-height: 70px;
	font-size: 18px;
	margin-top: -35px;	
	-webkit-transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}
.owl-theme.owl-arrows .owl-controls .owl-nav div:hover {
	background: #000;
}
.owl-theme.owl-arrows .owl-controls .owl-nav .owl-prev {
	left:-40px;
}
.owl-theme.owl-arrows .owl-controls .owl-nav .owl-next {
	right:-40px;
}
.owl-theme.owl-arrows:hover .owl-controls .owl-nav div {
	opacity: 1;
}
.owl-theme.owl-arrows:hover .owl-controls .owl-nav .owl-prev {
	left:0;
}
.owl-theme.owl-arrows:hover .owl-controls .owl-nav .owl-next {
	right:0;
}
