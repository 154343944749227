
.define-if-not-defined() {
  @gdpr-cookie-color: @headings-color;
  @gdpr-cookie-primary-color: @my-brand-primary;

}


#gdpr-cookie-message-wrapper.fixed {
  width: 100% !important;
  height: 100% !important;
  margin: 0 auto !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  background-color: rgba(0,0,0,0.5) !important;
  z-index: 2000000000 !important;
  overflow-y: auto !important;
  backdrop-filter: blur(3px);
}



#gdpr-cookie-message {
  width: 100%;
  background-color: #f8fafd;
  padding: 15px;
  box-shadow: 0 6px 6px rgba(0,0,0,0.25);
  font-family: Arial, Helvetica, sans-serif;
  z-index: 9999;
}

#gdpr-cookie-message.fixed {
  z-index: 999;
  width: calc(100% - 16px);
  margin-left: 0;
  position: fixed;
  top: 40%;
  left: 50%;
  padding: 10px;
  transform: translate(-50%, -50%);
  color: white;
  //font-size: 20px;
  max-width: 600px;
  max-height: 500px;
  height: fit-content;
  background-color: #FFF;
}




@media (min-width: 768px){

  #gdpr-cookie-message-inner {
    background-image: url("/images/cookies/logo.png");
    background-position: top left;
    background-repeat: no-repeat;
    padding-left: 110px;
  }

}

#gdpr-cookie-message h4 {
  color: @gdpr-cookie-color;
  font-family: Arial, Helvetica, sans-serif;
  font-size:15px;
  font-weight: 500;
  margin-bottom: 10px;
}
#gdpr-cookie-message h5 {
  color: @gdpr-cookie-color;
  font-family: Arial, Helvetica, sans-serif;
  font-size:15px;
  font-weight: 500;
  margin-bottom: 10px;
}
#gdpr-cookie-message p, #gdpr-cookie-message ul {
  color: @gdpr-cookie-color;
  font-size:15px;
  line-height: 1.5em;
  padding: 0px;
  margin: 0px;
}
#gdpr-cookie-message p:last-child {
  margin-bottom: 0;
  text-align: right;
}
#gdpr-cookie-message li {
  width: 100%;
  display: inline-block;
  padding: 0px;
  margin: 0px;

}
#gdpr-cookie-message a {
  color: @gdpr-cookie-color;
  text-decoration: underline;
  font-size:15px;
  padding-bottom: 2px;
}

#gdpr-cookie-message a:hover {
  color: @gdpr-cookie-color;
}

#gdpr-cookie-message a:focus {
  outline: 3px solid blue;
  outline-offset: 2px;

}


#gdpr-cookie-message button {
  border: 0;
  background: @gdpr-cookie-color;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size:15px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

#gdpr-cookie-message #gdpr-cookie-accept {
  background-color: @gdpr-cookie-primary-color;
  border: 2px solid @gdpr-cookie-primary-color;

  //padding: 15px 20px;
}

button#gdpr-cookie-advanced {
  background: #f8fafd;
  color: @gdpr-cookie-color;
  float: left;
  padding-left: 0px;
}

#gdpr-cookie-message.fixed button#gdpr-cookie-advanced {
  background: white;
}


@media (max-width: @grid-float-breakpoint) {

  #gdpr-cookie-message #gdpr-cookie-accept {
    margin-top: 10px;
    display: block;
  }

  button#gdpr-cookie-advanced {
    display: block;
    float: none;
    padding-bottom: 5px;

  }

  button#gdpr-cookie-deny  {
    display: block;

  }

  #gdpr-cookie-message.fixed {
    width: calc(100% - 2px);
    padding: 10px;
  }

  #gdpr-cookie-message{

    .buttons-container {
      display: flex; /* This will turn the container into a flex container */
      align-items: center; /* This will vertically align the buttons in the middle */
      justify-content: flex-start; /* This aligns the buttons to the left; change as needed */
    }

    .buttons-container button {
      margin-right: 10px; /* Adds some space between the buttons */
      /* Add other styles such as width, height, padding, etc., as needed */
    }

    .buttons-container button:last-child {
      margin-right: 0; /* Removes margin from the last button */
    }
    #gdpr-cookie-accept {
      margin-top: 0px;
    }

  }

}





#gdpr-cookie-message button:focus {
  outline: 3px solid blue;
  outline-offset: 2px;
}






button#gdpr-cookie-deny  {
  background: white;
  color: @gdpr-cookie-color;
  border: 2px solid @gdpr-cookie-primary-color;
  margin-right: 5px;
}




#gdpr-cookie-message button:disabled {
  opacity: 0.3;
}
#gdpr-cookie-message input[type="checkbox"] {
  float: none;
  margin-top: 0;
  margin-right: 5px;
}

#gdpr-cookie-message input[type="checkbox"]:focus {
  outline: 3px solid blue;
  outline-offset: 2px;
}



