.e-comm .panel-title label{
   font-weight: normal;
  font-size: 14px;
}

.e-comm .media-heading{
    font-size: 15px;
    text-transform: uppercase;
  font-weight: bold;
}

.e-comm .panel-heading{
  padding: 5px 10px;
}


.thumbnail-left{
  background-color: @mySoftBackgroundColor;
  margin-bottom: 10px;
  padding: 10px;
}

.thumbnail-left-img{
  display: table-cell;
  vertical-align: middle;
}

.thumbnail-left-text{
  display: table-cell;
  vertical-align: middle;
  padding-left: 10px;
}

.delivery-country-outer .form-group{
  margin-top: 18px;

}

.product-block.product-block-horizontal {

  display: table;
  width: 100%;
  padding: 0;


  .product-thumb {
    position: relative;
    width: 270px;
    padding: 20px 18px;
    background-color: #ddd;
  }

  .product-info {
    background-color: #ccc;
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    width: 100%;

    .l-title {
      margin: 0px;
      padding: 0px;
    }

    p{
      margin: 0px;
    }


    }


}

